import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  convertMediasToPG,
  generateTemplate,
  proGalleryEventListeners,
  selectorFactory,
  setStorage,
  setViewerEssentials,
} from '../../utils/Viewer';
import { PresetNames } from '../../enums';
import { PaginationService, igApi } from '../../api';
import { GRID_LIMIT } from '../../constants';
import { bi } from '../../bi';

export const onPageReady = async (
  $w,
  flowAPI: PlatformControllerFlowAPI,
  $widget,
) => {
  const { populateMedia, collapseApp, generateErrorState, initiateElements } =
    selectorFactory($w, flowAPI);
  const { isViewer, isPreview } = flowAPI.environment;
  const { t } = flowAPI.translations;
  const isViewMode = isViewer || isPreview;
  bi({ flowAPI }).appLoadedStart();
  initiateElements();

  try {
    console.log('onPageReady FlowApi', { wid: $widget });
    setViewerEssentials(flowAPI);
    const { accountId, preset } = $widget?.props;
    if (!isViewMode) {
      setStorage(flowAPI, $widget.props);
    }

    const isGrid = preset === PresetNames.Grid;

    let lightboxData = {};
    const { instance } = flowAPI.controllerConfig.appParams;
    if (accountId) {
      const { getAccount, getMedias } = igApi(instance);
      const userAccount = await getAccount(accountId);
      const limit = isGrid ? GRID_LIMIT : null;
      const paginationInstance = new PaginationService();
      const userMedias = await getMedias(accountId, {
        limit,
        isViewMode, // For widget not to fetch further data in editor on prop changes via panel
        paginationInstance,
      });
      const pgMediasApi = convertMediasToPG(userMedias);
      lightboxData = {
        user: userAccount,
        media: pgMediasApi,
        accountId,
        userMedias,
        paginationInstance,
      };
      populateMedia({ igMedia: pgMediasApi, igUser: userAccount });
      bi({ flowAPI }).appLoadedEnd(false);
    } else {
      const { templateMedias, templateUser } = await generateTemplate(
        flowAPI,
        $widget?.props,
      );
      lightboxData = { user: templateUser, media: templateMedias };
      populateMedia({
        igMedia: templateMedias,
        igUser: templateUser,
        isDemo: true,
      });
      bi({ flowAPI }).appLoadedEnd(true);
    }

    if (isViewMode) {
      proGalleryEventListeners(flowAPI, $w, lightboxData);
    }
  } catch (error) {
    // @ts-expect-error
    const errorData = error?.response?.data;
    console.log('===>Collapsing App', { errorData });
    isViewMode ? collapseApp() : generateErrorState(errorData, t);
  }
};
