import { ExpandPresets, PresetHeights, PresetIds, PresetNames } from '../enums';
import * as ClassicThumbnails from '../assets/presets/Classic';
import * as StudioThumbnails from '../assets/presets/Studio';

export interface WidgetPreset {
  name: PresetNames;
  id: PresetIds;
  connectedMobilePresetID: PresetIds;
  src: any;
  layout: Record<string, string>;
  defaultHeight?: number;
}

export const EXPAND_MODE_PRESETS = (isResponsive = true) => ({
  desktop: {
    layout: isResponsive ? ExpandPresets.Studio : ExpandPresets.Desktop,
    style: isResponsive ? ExpandPresets.Studio : ExpandPresets.Desktop,
  },
  mobile: {
    layout: ExpandPresets.Mobile,
    style: ExpandPresets.Mobile,
  },
});

const layout = { width: '240px' };

export const mainWidgetPresets = (isClassic = true): WidgetPreset[] => {
  const presetsThumbnails = isClassic ? ClassicThumbnails : StudioThumbnails;
  const { OneRow, TwoRows, Grid, SideBySide } = presetsThumbnails;
  return [
    {
      name: PresetNames.OneRow,
      id: PresetIds.OneRow,
      connectedMobilePresetID: PresetIds.OneRow_M,
      layout,
      defaultHeight: PresetHeights.OneRow,
      src: OneRow,
    },
    {
      name: PresetNames.TwoRows,
      id: PresetIds.TwoRows,
      connectedMobilePresetID: PresetIds.TwoRows_M,
      layout,
      defaultHeight: PresetHeights.TwoRows,
      src: TwoRows,
    },
    {
      name: PresetNames.SideBySide,
      id: PresetIds.SideBySide,
      connectedMobilePresetID: PresetIds.SideBySide_M,
      layout,
      defaultHeight: PresetHeights.SideBySide,
      src: SideBySide,
    },
    {
      name: PresetNames.Grid,
      id: PresetIds.Grid,
      connectedMobilePresetID: PresetIds.Grid_M,
      layout,
      src: Grid,
    },
  ];
};

export const getPresetNameById = (presetId: PresetIds): PresetNames | null => {
  for (const key in PresetIds) {
    if (PresetIds[key] === presetId) {
      return PresetNames[key];
    }
  }
  return null;
};

export const GRID_LIMIT = 9;
